import React from 'react';
import Button from '../../../components/button';
import Text from '../../../components/text';

import { ArrowLeft } from '@benepass/icons';
import { useHistory } from 'react-router-dom';

type Props = {
  title?: string | React.ReactNode;
  backButton?: {
    text?: string;
    fallbackRoute?: string;
    fallbackText?: string;
  };
  // TO DO: implement
  // ui?: {
  //   hideTitle?: boolean;
  //   hideBackButton?: boolean;
  // };
};

const FullscreenHeader = ({ title, backButton = { text: 'Go back' } }: Props) => {
  const history = useHistory();
  const canGoBack = React.useMemo(() => history.length > 2, [history.length]);

  const handleGoBackClick = React.useCallback(() => {
    if (canGoBack) {
      history.goBack();
    } else if (backButton?.fallbackRoute) {
      history.push(backButton?.fallbackRoute);
    } else {
      history.push('/');
    }
  }, [history, canGoBack, backButton?.fallbackRoute]);

  return (
    <div className="flex md:shadow-1dp px-2 md:px-6 lg:px-10 xl:px-16 py-4 md:py-7 sticky top-0 bg-white z-40">
      <div className="grid grid-cols-4 md:grid-cols-3 mx-auto w-full max-w-screen-2xl items-center">
        <Button variant="link" className="justify-start" onClick={handleGoBackClick}>
          <ArrowLeft size="20px" />
          <Text type="header-5" className="hidden md:block text-current">
            {(canGoBack ? backButton?.text : backButton?.fallbackText) || 'Go back'}
          </Text>
        </Button>

        <Text
          type="header-2"
          className="text-grayscale-80 text-header-3 md:text-header-2 col-span-2 md:col-span-1 text-center"
        >
          {title}
        </Text>
      </div>
    </div>
  );
};

export default FullscreenHeader;
