import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import routes from '.';

const OnboardingPage = lazy(() => import('./onboarding-page'));
const GenericOnboardingPage = lazy(() => import('./generic-onboarding.page'));

const Onboarding = () => (
  <Switch>
    <Route exact path={[routes.index, routes.editAddress]}>
      <Helmet>
        <title>Welcome - Benepass</title>
      </Helmet>

      <OnboardingPage />
    </Route>

    <Route exact path={[routes.genericOnboarding]}>
      <Helmet>
        <title>Benepass</title>
      </Helmet>

      <GenericOnboardingPage />
    </Route>
  </Switch>
);

export default Onboarding;
