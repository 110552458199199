import React from 'react';

import BaseContainer from '../container';

import { PageContainerProvider } from './hooks';

type Props = React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;

export const Container = ({ children, ...props }: Props) => (
  <PageContainerProvider>
    <BaseContainer {...props}>{children}</BaseContainer>
  </PageContainerProvider>
);
