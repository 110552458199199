import React from 'react';
import useLocalStorage from 'utils/src/hooks/useLocalStorage';

import { useHistory } from 'react-router-dom';
import { getFirstPath } from 'utils/src/url-utils';

type Value = [
  string,
  (value: string) => unknown,
  {
    add: (id: string, callback: () => unknown) => void;
    remove: (id: string) => void;
  },
];

const Context = React.createContext<Value | undefined>(undefined);
export const localStorageKey = 'workspaces::selected-workspace';

export const useUserWorkspace = (): Value => {
  const context = React.useContext<Value>(Context as unknown as React.Context<Value>);
  // @notes
  //  it will throw an error if it's not within the user workspace's Provider.
  return context;
};

/**
 * @notes
 *  for now the `UserWorkspaceProvider` is just using `useLocalStorage` and we can
 *  basically just use `useLocalStorage` everywhere but we decided to keep this in
 *  we want to add some side-effects when changing the workspace (for example tracking
 *  when there's a workspace change or depending on where we are in the app we can
 *  probably move the user to a different route).
 */
export const UserWorkspaceProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(localStorageKey, '');
  const history = useHistory();

  const promises = React.useMemo(() => new Map<string, (value: unknown) => unknown>(), []);
  const callbacks = React.useMemo(() => new Map<string, () => unknown>(), []);

  const handleLocalStorageChange = React.useCallback(
    async (value: string) => {
      setLocalStorageValue(value);

      const promise = new Promise((resolve) => promises.set(value, resolve));
      await Promise.race([promise, new Promise((resolve) => setTimeout(resolve, 1000))]);

      const values = Array.from(callbacks.values());
      await Promise.all(values.map((callback) => callback()));
    },
    [setLocalStorageValue, callbacks, promises]
  );

  React.useEffect(() => {
    const resolve = promises.get(localStorageValue);

    if (resolve) {
      resolve(true);
      promises.delete(localStorageValue);
    }
  }, [localStorageValue, promises]);

  React.useEffect(() => {
    const navigationCallbackId = 'root:navigation';

    if (!callbacks.get(navigationCallbackId)) {
      callbacks.set(navigationCallbackId, () => history.replace(`${getFirstPath(history.location.pathname)}`));
    }
  }, [history, callbacks]);

  return (
    <Context.Provider
      value={[
        localStorageValue,
        handleLocalStorageChange,
        {
          add: (id, callback) => callbacks.set(id, callback),
          remove: (id) => callbacks.delete(id),
        },
      ]}
    >
      {children}
    </Context.Provider>
  );
};

export default UserWorkspaceProvider;
