import React from 'react';
import routes from '@pages';

import { Sidebar } from 'react-components/src/ui/layout/dynamic-layout-with-sidebar';
import { useUserWorkspacesQuery } from '@app/data/user-workspaces.query';
import { useUserWorkspace } from '@hooks/use-user-workspace';
import { Redirect } from 'react-router-dom';
import { WorkspaceSwitcher, WorkspaceRedirectionCheck } from '@widgets/workspaces';
import { shouldForceWorkspaceSelection } from '@widgets/workspaces/workspaces.utils';

interface WindowWithAnalytics extends Window {
  analytics?: {
    identify: (id: string) => void;
  };
}

const SidebarWorkspaceSelector = () => {
  const workspaces = useUserWorkspacesQuery();
  const [userWorkspace] = useUserWorkspace();

  const selectedWorkspace = React.useMemo(
    () => workspaces.find((workspace) => userWorkspace === workspace.apiID),
    [workspaces, userWorkspace]
  );

  // @notes
  //  `window.analytics` is CIO's analytics service that can be used afterwards for sending events (`track`)
  //  or in-app notifications or any other use case that requires it.
  React.useEffect(() => {
    if (selectedWorkspace?.employment?.apiID && (window as WindowWithAnalytics).analytics) {
      (window as WindowWithAnalytics).analytics?.identify(selectedWorkspace.employment.apiID);
    }
  }, [selectedWorkspace]);

  if (shouldForceWorkspaceSelection([...workspaces], userWorkspace)) {
    return <Redirect to={routes.workspaces.selection} />;
  }

  if (!selectedWorkspace || (workspaces.length || 0) <= 1) {
    return <Sidebar.DefaultLogo theme="dark" />;
  }

  return (
    <>
      <WorkspaceSwitcher>
        <Sidebar.DefaultLogo theme="dark" />
      </WorkspaceSwitcher>

      <React.Suspense fallback={null}>
        <WorkspaceRedirectionCheck />
      </React.Suspense>
    </>
  );
};

const Wrapper = () => (
  <React.Suspense fallback={<div className="w-10 h-10 rounded-xl bg-indigo-90 animate-pulse" />}>
    <SidebarWorkspaceSelector />
  </React.Suspense>
);

export default Wrapper;
