import * as Sentry from '@sentry/react';
import current from '@config';

import { CaptureConsole } from '@sentry/integrations';

import packageJSON from '../../../package.json';

export const initSentry = () => {
  Sentry.init({
    dsn: current.sentry.dsn,
    release: `employee@${packageJSON.version}`,
    environment: current.sentry.environment,
    attachStacktrace: true,
    denyUrls: ['localhost'],
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        ignore: ['.ignore-me', '[data-replays-ignore]'],
        block: ['.block-me', '[data-replays-block]'],
        unblock: ['.unblock-me', '[data-replays-unblock]'],
        mask: ['.mask-me', '[data-replays-mask]'],
        unmask: ['.unmask-me', '[data-replays-unmaask]'],
      }),
    ],
    ignoreErrors: [/ResizeObserver/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
  });
};
