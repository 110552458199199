import React from 'react';
import useBreakpoint from 'utils/src/hooks/useBreakpoint';

import customTwMerge from '../../../../utils/twMerge';
import Text from '../../../../components/text';

import { down } from 'styled-breakpoints';

type Props = Omit<React.ComponentProps<typeof Text>, 'children'> & {
  children: string;
};

const Title = ({ children, ...props }: Props) => {
  const isMobile = useBreakpoint(down('sm'));

  return (
    <Text
      {...props}
      className={customTwMerge('text-grayscale-100', props.className)}
      type={isMobile ? 'header-1' : 'display-2'}
    >
      {children}
    </Text>
  );
};

export default Title;
