import React from 'react';
import SidebarButton from 'react-components/src/ui/layout/dynamic-layout-with-sidebar/sidebar-button';

import routes from '@pages';

import { usePageContainer } from 'react-components/src/ui/layout/page';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Text } from 'react-components';
import { Reimbursement } from '@benepass/icons';

import { useViewerQuery } from '../../data/viewer-query';

const SidebarFooter = () => {
  const viewer = useViewerQuery();
  const location = useLocation();
  const { isCollapsed } = usePageContainer();

  return (
    <div className={['flex flex-col gap-6 pb-5', isCollapsed ? 'px-2' : 'px-6'].join(' ')}>
      {viewer?.canSubmitReimbursement ? (
        <Link
          to={routes.expenses.create.index}
          type="button"
          className={`w-full h-14 bg-grayscale-0 justify-center flex items-center m-auto ${
            isCollapsed ? 'p-2' : 'py-3'
          } flex flex-row rounded-lg gap-3 text-indigo-100 disabled:text-grayscale-48 bg-white hover:text-indigo-100 hover:bg-indigo-10 focus:bg-indigo-20`}
        >
          <Reimbursement size="20px" className="hover:text-indigo-100" />
          {!isCollapsed ? <Text type="header-3">Get reimbursed</Text> : null}
        </Link>
      ) : null}

      <div className="border border-b border-indigo-70" />

      <div className="flex flex-col gap-1">
        <SidebarButton href="https://benepass.zendesk.com/hc/en-us" target="_blank" icon="Question">
          Contact support
        </SidebarButton>

        <SidebarButton
          to={routes.settings.index}
          active={!!matchPath(location.pathname, { path: routes.settings.all, exact: true })}
          icon="Engine"
        >
          Settings
        </SidebarButton>
      </div>
    </div>
  );
};

export default SidebarFooter;
