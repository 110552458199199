import React from 'react';
import Route from '@app/components/route';

import { Switch } from 'react-router-dom';

import routes from '.';

const MerchantDetailsPage = React.lazy(() => import('./merchant-details-page'));
const DealDetailsPage = React.lazy(() => import('./deal-details-page'));

const Explore = () => (
  <Switch>
    <Route path={routes.dealDetails} title="Deal">
      <DealDetailsPage />
    </Route>

    <Route path={routes.merchantDetails} title="Merchant">
      <MerchantDetailsPage />
    </Route>
  </Switch>
);

export default Explore;
