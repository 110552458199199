import { graphql } from 'relay-runtime';
import { useLazyLoadQuery } from 'react-relay';

import type {
  userWorkspacesQuery as Query,
  userWorkspacesQuery$data as Response,
} from './__generated__/userWorkspacesQuery.graphql';

export type UserWorkspace = NonNullable<Response['res']>[number];

const query = graphql`
  query userWorkspacesQuery {
    res: user__Identity_GetWorkspaces {
      apiID
      type
      activeAccounts
      loginRequired

      employment {
        apiID
        isActive

        employer {
          name
          logo
        }
      }
    }
  }
`;

export const useUserWorkspacesQuery = () => {
  const { res } = useLazyLoadQuery<Query>(query, {}, { fetchPolicy: 'store-or-network' });
  return res;
};

export const isEmploymentInactive = (workspace: UserWorkspace) => {
  return workspace.type === 'employment' && !workspace.employment?.isActive;
};
