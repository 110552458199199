import React from 'react';

import Container from '../container/with-responsive-padding';

import { PageContainerProvider } from './hooks';

type Props = React.HTMLAttributes<HTMLDivElement>;

const ContainerWithPadding = ({ children, ...props }: Props) => (
  <PageContainerProvider>
    <Container {...props}>{children}</Container>
  </PageContainerProvider>
);

export default ContainerWithPadding;
