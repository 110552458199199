import React from 'react';
import DynamicContainerWithSidebar, { Sidebar } from 'react-components/src/ui/layout/dynamic-layout-with-sidebar';
import FullScreenContainer from 'react-components/src/ui/layout/fullscreen-container';

import { useViewerQuery } from '@app/data/viewer-query';
import { LayoutLoginContainer } from 'react-components';

import Routes from '../../../pages';
import SidebarFooter from './sidebar-footer';
import SidebarWorkspaceSelector from './sidebar-workspace-selector';

import type { SidebarRoute } from 'react-components/src/ui/layout/dynamic-layout-with-sidebar';

type Props = React.PropsWithChildren<unknown>;

const MainLayout = ({ children }: Props) => {
  const viewer = useViewerQuery();

  const sidebarRoutes: SidebarRoute[] = React.useMemo(
    () => [
      {
        key: 'home',
        children: 'Home',
        route: Routes.home.index,
        icon: 'Home',
        enabled: true,
        enabledRoutes: [Routes.home.index, Routes.home.home],
      },
      {
        key: 'accounts',
        children: 'Accounts',
        icon: 'Wallet',
        route: Routes.accounts.index,
        enabled: true,
        enabledRoutes: Object.values(Routes.accounts),
      },
      {
        key: 'cards',
        children: 'Cards',
        icon: 'Card',
        route: Routes.cards.index,
        enabled: true,
        enabledRoutes: [Routes.cards.all],
      },
      {
        key: 'documents',
        children: 'Documents',
        icon: 'Form',
        route: Routes.settings.documents,
        enabled: viewer?.hasHSA || false,
        enabledRoutes: [
          Routes.settings.documents,
          Routes.settings.documentsForms,
          Routes.settings.documentsHsaStatements,
        ],
      },
      {
        key: 'explore',
        children: 'Explore',
        icon: 'Merchant',
        route: Routes.explore.index,
        enabled: true,
        enabledRoutes: [Routes.explore.all],
      },
    ],
    [viewer?.hasHSA]
  );

  return (
    <DynamicContainerWithSidebar
      sidebarRoutes={sidebarRoutes}
      sidebarFooter={<SidebarFooter />}
      layouts={[
        // routes using `FullScreenContainer` as layout
        ...[
          Routes.preApproval.index,
          Routes.expenses.create.all,
          Routes.onboarding.index,
          Routes.onboarding.genericOnboarding,
          Routes.expenses.magicExpense.all,
        ].map((route) => ({
          layout: FullScreenContainer,
          route,
        })),
        {
          layout: LayoutLoginContainer,
          route: Routes.workspaces.all,
        },
      ]}
    >
      <Sidebar.Logo>
        <SidebarWorkspaceSelector />
      </Sidebar.Logo>

      {children}
    </DynamicContainerWithSidebar>
  );
};

export default MainLayout;
