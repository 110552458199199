import { generatePath } from 'react-router-dom';

import onboarding from './onboarding';
import home from './home';
import cards from './cards';
import accounts from './accounts';
import explore from './explore';
import newExpense from './new-expense';
import settings from './settings';
import transactions from './transactions';
import merchants from './merchants';
import workspaces from './workspaces';
import fileUploads from './file-uploads';
import preApproval from './pre-approval';
import magicExpense from './magic-expense';

const pages = {
  onboarding,
  home,
  transactions,
  cards,
  accounts,
  explore,
  settings,
  merchants,
  workspaces,
  fileUploads,
  preApproval,
  expenses: {
    create: newExpense,
    magicExpense,
  },
};

export const getPathByPattern = (pattern: string, attributes: Record<string, string> = {}): string => {
  return generatePath(pattern, attributes);
};

export default pages;
