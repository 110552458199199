import React from 'react';

type Props = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const Container = ({ children, ...props }: Props): JSX.Element => (
  <div className="flex flex-row" {...props}>
    {children}
  </div>
);

export default Container;
