import { createContext, useContext, useEffect, useState } from 'react';
import { isEqual } from 'utils/src/core-utils';
import { interpret } from 'xstate';
import authenticationMachine from './machine/machine';

const AUTHENTICATED = ['loggedIn'];
const LOADING = ['start', 'authenticating'];
const inState = (states, state) => states.some(state.matches);

const auth = interpret(authenticationMachine);

export const getAccessToken = async () => auth.getSnapshot().context.id_token;
export const getAccessTokenSync = () => auth.getSnapshot().context.id_token;

export const loginWithRedirect = () => {
  auth.send('logout');
};
export const logout = () => {
  auth.send('logout');
};

export const AuthenticationContext = createContext({});

export const useAuthentication = () => {
  return useContext(AuthenticationContext);
};

export const Status = {
  loading: 'loading',
  success: 'success',
  error: 'error',
};

export const useAuthCheck = () => {
  const state = auth.getSnapshot();
  if (inState(AUTHENTICATED, state)) {
    return { status: 'success' };
  }
  return { status: state.matches('error') ? 'error' : 'loading' };
};

// eslint-disable-next-line react/prop-types
export const AuthenticationProvider = ({ children }) => {
  const [authParams, setAuthParams] = useState({
    isLoading: true,
    isAuthenticated: false,
    getAccessToken,
    logout,
    loginWithRedirect,
  });

  useEffect(() => {
    const listener = (state) => {
      const { user } = state.context;

      const temp = {
        user,
        isAuthenticated: inState(AUTHENTICATED, state),
        isLoading: inState(LOADING, state),
        getAccessToken,
        logout,
        loginWithRedirect,
      };

      if (!isEqual(authParams, temp)) {
        setAuthParams(temp);
      }
    };

    const subscription = auth.subscribe(listener);

    return subscription.unsubscribe;
  }, [authParams, setAuthParams]);

  useEffect(() => {
    auth.start();
  }, []);

  return <AuthenticationContext.Provider value={authParams}>{children}</AuthenticationContext.Provider>;
};
