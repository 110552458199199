import React, { useMemo, createContext, useContext } from 'react';
import useLocalStorage from 'utils/src/hooks/useLocalStorage';

type Value = {
  isCollapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
};

const initialState = window.matchMedia('(max-width: 1279px)').matches;
const Context = createContext<Value | undefined>(undefined);

export const PageContainerProvider = ({ children }: React.PropsWithChildren<unknown>): JSX.Element => {
  const [isCollapsed, setCollapsed] = useLocalStorage('sidebar-collapse-state', initialState);
  const value: Value = useMemo(() => ({ isCollapsed, setCollapsed }), [isCollapsed, setCollapsed]);
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const usePageContainer = (): Value => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(`usePage must be used within a PageProvider`);
  }

  return context;
};
