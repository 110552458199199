import React from 'react';
import { Info as InfoIcon } from '@benepass/icons';
import styles from './Info.module.css';
import { grayscale } from '@benepass/colors';
import Tooltip from './Tooltip';

type Props = {
  message: string | React.ReactNode;
  variant?: 'info' | 'gray';
};

const Info = ({ message, variant = 'info' }: Props): JSX.Element => (
  <Tooltip content={message}>
    <div className={variant === 'gray' ? styles.gray : styles.info}>
      <InfoIcon color={variant === 'gray' ? grayscale[48] : 'black'} />
    </div>
  </Tooltip>
);

export default Info;
