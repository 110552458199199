import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useAuthentication } from '@data/auth/transition';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import { createEnvironment } from 'utils/src/RelayEnvironment';
import { OverlayLoader, PageError, Message, SuspenseWrapper } from 'react-components';
import { ErrorBoundary } from 'react-error-boundary';
import { LogCategories, errorHandler } from 'utils/src/Logs';

import * as Sentry from '@sentry/react';
import Routes from '@pages/routes';
import current from '@config';

import { ApolloProvider } from '@data/services/apollo';
import { contactSupportMessage } from 'utils/src/Support';
import { ToastProvider } from 'react-components/src/components/toast';
import { DialogProvider } from 'react-components/src/components/dialog';
import { useUserWorkspacesQuery } from '@app/data/user-workspaces.query';
import { useUserWorkspace } from '@hooks/use-user-workspace';

import projectPackage from '../../package.json';
import Providers from './providers';
import Authenticator from './authenticator';

const { version } = projectPackage;
const { MessageTypes } = Message;

const headers = {
  'x-benepass-platform': 'web',
  'x-benepass-client': 'employee-web',
  'x-benepass-version': process.env.npm_package_version || version,
};

const WorkspaceChecker = () => {
  const [userWorkspace, setUserWorkspace] = useUserWorkspace();
  const workspaces = useUserWorkspacesQuery();

  useEffect(() => {
    if (typeof userWorkspace === 'string' && !userWorkspace && (workspaces || []).length === 1) {
      const [firstWorkspace] = workspaces;

      if (firstWorkspace?.loginRequired === false) {
        setUserWorkspace(firstWorkspace.apiID);
      }
    }
  }, [userWorkspace, workspaces, setUserWorkspace]);

  return null;
};

const AppMain = () => {
  const { user, getAccessToken } = useAuthentication();
  const [userWorkspace, setUserWorkspace] = useUserWorkspace();

  const relayEnvironment = createEnvironment({
    graphQLUrl: current.api.graphql,
    getAccessTokenSilently: getAccessToken,
    email: user?.email,
    extraHeaders: {
      ...headers,
      ...(userWorkspace ? { 'x-benepass-workspace-id': userWorkspace } : {}),
    },
    errorHandler: async (error) => {
      /**
       * @notes
       *  while testing MEW there were some cases where the user ended having a workspace id set
       *  and the user was not in the same workspace as the one they were trying to access
       *  so as workaround we will clear the user workspace and that will triger the checks to run
       *  again. We will be adding more specific handlers and checks for certain queries and then
       *  we'll be able to remove this handler.
       */
      if (error.message === 'detail: Workspace not found.') {
        await setUserWorkspace('');
      }
    },
  });

  useEffect(() => {
    if (user) {
      Sentry.setUser(user);
    }
  }, [user]);

  return (
    <RelayEnvironmentProvider environment={relayEnvironment as never}>
      <ApolloProvider>
        <SuspenseWrapper loader={<OverlayLoader message="Loading your data..." />}>
          <ErrorBoundary
            FallbackComponent={() => (
              <PageError title="Server Unreachable">
                <Message type={MessageTypes.ERROR}>
                  Something is wrong on Benepass Servers. {contactSupportMessage}
                </Message>
              </PageError>
            )}
            onError={errorHandler(LogCategories.UNKNOWN_ERROR)}
          >
            {!userWorkspace ? <WorkspaceChecker /> : null}
            <DialogProvider />
            <ToastProvider />
            <Routes />
          </ErrorBoundary>
        </SuspenseWrapper>
      </ApolloProvider>
    </RelayEnvironmentProvider>
  );
};

const App = () => (
  <Authenticator>
    <Router>
      <Providers>
        <AppMain />
      </Providers>
    </Router>
  </Authenticator>
);

export default App;
