import { useCallback, useState } from 'react';

import * as Modal from 'react-components/src/components/modal';
import Button from 'react-components/src/components/button';
import Text from 'react-components/src/components/text';

import { useUserWorkspace } from '@hooks/use-user-workspace';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';

import type { workspaceRedirectionWidgetQuery as Query } from './__generated__/workspaceRedirectionWidgetQuery.graphql';

const query = graphql`
  query workspaceRedirectionWidgetQuery {
    res: user__Identity_GetWorkspaceRedirection {
      title
      reason
      workspace {
        apiID
      }
    }
  }
`;

const WorkspaceRedirectionCheck = () => {
  const { res } = useLazyLoadQuery<Query>(query, {});
  const [, setUserWorkspace] = useUserWorkspace();
  const [showModal, setShowModal] = useState<boolean>(true);

  const onClick = useCallback(() => {
    setShowModal(false);

    if (res?.workspace) {
      // @notes
      //  Adding the `setTimeout` here because we are closing a modal and we need to wait for
      //  the modal to close before we set the workspace otherwise there will be a conflict and
      //  it will wait until the workspace is changed the DOM re-mounts.
      setTimeout(() => setUserWorkspace(res.workspace.apiID), 10);
    }
  }, [res, setUserWorkspace, setShowModal]);

  if (res?.workspace?.apiID) {
    return (
      <Modal.Root open={showModal}>
        <Modal.Content hideCloseButton>
          <Modal.Title>{res.title}</Modal.Title>

          <div>
            <Text>{res.reason}</Text>
          </div>

          <Modal.Footer className="w-full flex px-7 pt-7">
            <Button className="w-full" onClick={onClick}>
              Continue
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    );
  }

  return null;
};

export default WorkspaceRedirectionCheck;
