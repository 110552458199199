import { fromPairs } from 'utils/src/core-utils';
import { parseCredentials, keyForState } from './pkce';

const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'refresh-token';

// Current time in seconds
export const now = () => Math.floor(Date.now() / 1000);

const loadRefreshToken = () => window.localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
const storeRefreshToken = (t) => t && window.localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, t);
const clearRefreshToken = () => window.localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);

const readAndClearUrlParams = () => {
  const query = fromPairs([...new URLSearchParams(window.location.search)]);
  const fragment =
    new URLSearchParams(window.location.hash.replace(/^.+?authentication_result=/, 'x=')).get('x') || '{}';

  if (query.code || query.state || window.location.hash.match(/authentication_result/)) {
    window.history.replaceState({}, '', window.location.pathname);
  }

  return { query, fragment: parseCredentials(fragment) };
};

export { loadRefreshToken, storeRefreshToken, clearRefreshToken, readAndClearUrlParams, keyForState };
