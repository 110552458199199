/**
 * @generated SignedSource<<9627ee767421fe82c87e29d0a3394405>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type userWorkspacesQuery$variables = {};
export type userWorkspacesQuery$data = {
  readonly res: ReadonlyArray<{
    readonly activeAccounts: number;
    readonly apiID: string;
    readonly employment: {
      readonly apiID: string;
      readonly employer: {
        readonly logo: string;
        readonly name: string;
      };
      readonly isActive: boolean;
    } | null;
    readonly loginRequired: boolean;
    readonly type: string;
  }>;
};
export type userWorkspacesQuery = {
  response: userWorkspacesQuery$data;
  variables: userWorkspacesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "apiID",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activeAccounts",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "loginRequired",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "UserWorkspaceEmploymentEmployer",
  "kind": "LinkedField",
  "name": "employer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "userWorkspacesQuery",
    "selections": [
      {
        "alias": "res",
        "args": null,
        "concreteType": "UserWorkspace",
        "kind": "LinkedField",
        "name": "user__Identity_GetWorkspaces",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserWorkspaceEmployment",
            "kind": "LinkedField",
            "name": "employment",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "userWorkspacesQuery",
    "selections": [
      {
        "alias": "res",
        "args": null,
        "concreteType": "UserWorkspace",
        "kind": "LinkedField",
        "name": "user__Identity_GetWorkspaces",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserWorkspaceEmployment",
            "kind": "LinkedField",
            "name": "employment",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8e768aa8fbbbd1be496b9d8e45c331ff",
    "id": null,
    "metadata": {},
    "name": "userWorkspacesQuery",
    "operationKind": "query",
    "text": "query userWorkspacesQuery {\n  res: user__Identity_GetWorkspaces {\n    apiID\n    type\n    activeAccounts\n    loginRequired\n    employment {\n      apiID\n      isActive\n      employer {\n        name\n        logo\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "173bbffcdda394cd953219b17af5f34c";

export default node;
