import { graphql } from 'relay-runtime';
import { useLazyLoadQuery } from 'react-relay';

import type { viewerQuery as Query } from './__generated__/viewerQuery.graphql';

const query = graphql`
  query viewerQuery {
    viewer {
      apiID
      benepassCashAccount
      canLinkBankAccount
      canViewBPCash
      canSubmitReimbursement
      email
      firstName
      fullName
      nickname
      hasHSA
      canEnableOverages
      overagesAchDebitEnabled
    }
  }
`;

export const useViewerQuery = () => {
  const { viewer } = useLazyLoadQuery<Query>(query, {}, { fetchPolicy: 'store-or-network' });
  return viewer;
};
