import React from 'react';
import Loader from 'react-components/src/ui/layout/fullscreen-container/fullscreen-loader';

import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { SuspenseWrapper } from 'react-components';
import FullscreenContainer from 'react-components/src/ui/layout/fullscreen-container';
import routes from '.';

const PreApprovalPage = React.lazy(() => import('./get-pre-approval.page'));
const PreApprovalSubmissionsPage = React.lazy(() => import('./submissions-list'));

const Home = () => (
  <Switch>
    <Route exact path={[routes.index]}>
      <FullscreenContainer className="bg-grayscale-2 min-h-screen">
        <Helmet>
          <title>Get pre-approval</title>
        </Helmet>

        <SuspenseWrapper loader={<Loader />}>
          <PreApprovalPage />
        </SuspenseWrapper>
      </FullscreenContainer>
    </Route>

    <Route exact path={[routes.submissionsList]}>
      <Helmet>
        <title>Pre-approval submissions</title>
      </Helmet>

      <PreApprovalSubmissionsPage />
    </Route>
  </Switch>
);

export default Home;
