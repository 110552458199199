import * as Dropdown from 'react-components/src/components/dropdown';
import Avatar from 'react-components/src/components/avatar';
import Text from 'react-components/src/components/text';

import { type UserWorkspace, useUserWorkspacesQuery, isEmploymentInactive } from '@app/data/user-workspaces.query';
import { useUserWorkspace } from '@hooks/use-user-workspace';
import { logout } from '@data/auth/transition';
import { Check, Lock } from '@benepass/icons';

type Props = {
  setIsDropdownOpen: (value: boolean) => void;
  selectedWorkspace?: UserWorkspace;
};

const Content = ({ setIsDropdownOpen, selectedWorkspace }: Props) => {
  const workspaces = useUserWorkspacesQuery();
  const [, setUserWorkspace] = useUserWorkspace();

  return (
    <Dropdown.Content className="rounded-sm min-w-[300px]">
      {workspaces.map((workspace) => (
        <Dropdown.Item
          key={workspace.apiID}
          onSelect={() => {
            setIsDropdownOpen(false);
            // @notes
            //  we added the `setTimeout` here because otherwise it was changing the workspace and unmounting
            //  the component with dropdown open causing it was preventing the user to click anything else.
            setTimeout(() => {
              if (workspace.loginRequired) {
                return logout();
              }

              return setUserWorkspace(workspace.apiID);
            }, 100);
          }}
          className="flex gap-4"
        >
          <div className=" self-start rounded-full aspect-square bg-grayscale-0 object-cover flex items-center justify-center">
            <Avatar
              name={workspace.employment?.employer?.name || 'HSA'}
              src={workspace?.employment?.employer.logo}
              size="xs"
            />
          </div>

          <div className="flex flex-col">
            {workspace.employment?.employer?.name || 'HSA'}

            {isEmploymentInactive(workspace) ? (
              <Text size="sm" className="text-blue-100" as="span">
                Prior employment
              </Text>
            ) : null}
          </div>

          <div className="ml-auto">
            {selectedWorkspace?.apiID === workspace.apiID ? <Check className="text-grayscale-48" /> : null}
            {selectedWorkspace?.apiID !== workspace.apiID && workspace.loginRequired ? (
              <Lock className="text-grayscale-48" />
            ) : null}
          </div>
        </Dropdown.Item>
      ))}
    </Dropdown.Content>
  );
};

export default Content;
