import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import routes from '.';

const WorkspacesSelectionPage = lazy(() => import('./workspaces.page'));

const Home = () => (
  <Switch>
    <Route exact path={[routes.index, routes.selection]}>
      <Helmet>
        <title>Select your workspace</title>
      </Helmet>

      <WorkspacesSelectionPage />
    </Route>
  </Switch>
);

export default Home;
