import { graphql, useLazyLoadQuery } from 'react-relay';
import type { checkOnboardingQuery as Query } from './__generated__/checkOnboardingQuery.graphql';

const query = graphql`
  query checkOnboardingQuery {
    onboardings: user__Onboarding_GetOnboardings {
      apiID
      isComplete
    }
  }
`;

export const useCheckOnboardingQuery = () => {
  const { onboardings } = useLazyLoadQuery<Query>(query, {}, { fetchPolicy: 'store-or-network' });
  return onboardings;
};
