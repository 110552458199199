import React from 'react';
import customTwMerge from '../../../utils/twMerge';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  onlyHorizontal?: boolean;
};

const WithResponsivePadding = ({ onlyHorizontal, ...props }: Props) => (
  <div
    {...props}
    className={customTwMerge(
      'px-4 md:px-8 xl:px-12',
      onlyHorizontal ? '' : 'pt-5 md:pt-12 pb-5 md:pb-12',
      props.className || ''
    )}
  />
);

export default WithResponsivePadding;
