import React from 'react';
import useBreakpoint from 'utils/src/hooks/useBreakpoint';

import customTwMerge from '../../../../utils/twMerge';
import Text from '../../../../components/text';

import { down } from 'styled-breakpoints';

type Props = Omit<React.ComponentProps<typeof Text>, 'children'> & {
  children: string;
};

const Subtitle = ({ children, ...props }: Props) => {
  const isMobile = useBreakpoint(down('sm'));

  return (
    <Text
      {...props}
      className={customTwMerge('text-grayscale-64', props.className)}
      type="body"
      size={isMobile ? 'lg' : 'xl'}
    >
      {children}
    </Text>
  );
};

export default Subtitle;
