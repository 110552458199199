const test = {
  test: true,
  track: false,
  auth: {
    domain: 'auth.benefitsapi.com',
    clientId: 'GcKuehS4eYHR9Bo2T70ffovSMGsXRavm',
    audience: 'https://api.benefitsapi.com',
  },
  api: {
    origin: 'http://benefits_api.com/',
    graphql: 'http://benefits_api.com/graphql',
  },
  plaid: {
    origin: 'http://link_benepass.com/',
  },
  cms: { origin: 'http://cms_api.com/' },
  street: {
    autocomplete: 'https://us-autocomplete-pro.api.smartystreets.com',
  },
};

const staging = {
  test: false,
  track: true,
  auth: {
    domain: 'benefitsapi-dev.auth0.com',
    clientId: 'TfcPt4awj6CT419Fsp6OKXHp4mWrsmDs',
    audience: 'testing',
  },
  api: {
    origin:
      process.env.NEXT_PUBLIC_BENEFITS_API || process.env.REACT_APP_BENEFITS_API || 'https://staging.benefitsapi.com/',
    graphql:
      process.env.NEXT_PUBLIC_GRAPHQL || process.env.REACT_APP_GRAPHQL || 'https://staging.benefitsapi.com/graphql',
  },
  cms: {
    origin: process.env.NEXT_PUBLIC_CMS_API || process.env.REACT_APP_CMS_API || 'https://cms.benefitsapi.com/',
  },
  plaid: {
    origin: 'https://link.development.getbenepass.com/',
  },
  street: {
    autocomplete: 'https://us-autocomplete-pro.api.smartystreets.com',
  },
  sentry: {
    dsn: 'https://f467ef38040a4a778c84a5375550e44b@o463927.ingest.sentry.io/5656326',
    environment: 'staging',
  },
};

const production = {
  test: false,
  track: ((process.env.NEXT_PUBLIC_TRACK_SENTRY || process.env.REACT_APP_TRACK_SENTRY) ?? '1') === '1',
  auth: {
    domain: 'cognito.benefitsapi.com',
    client_id: '6l7jeu4r44kgndgeab4aot355m',
    redirect_uri:
      process.env.NEXT_PUBLIC_AUTH_REDIRECT ||
      process.env.REACT_APP_AUTH_REDIRECT ||
      'https://app.getbenepass.com/?v=2',
    client: process.env.NEXT_PUBLIC_AUTH_CLIENT || process.env.REACT_APP_AUTH_CLIENT || 'employeeWebApp',
    signOnUrl:
      process.env.NEXT_PUBLIC_SIGN_ON_URL || process.env.REACT_APP_SIGN_ON_URL || 'https://signon.benefitsapi.com/',
  },
  api: {
    origin:
      process.env.NEXT_PUBLIC_BENEFITS_API || process.env.REACT_APP_BENEFITS_API || 'https://api.benefitsapi.com/',
    graphql: process.env.NEXT_PUBLIC_GRAPHQL || process.env.REACT_APP_GRAPHQL || 'https://api.benefitsapi.com/graphql',
    beef: process.env.NEXT_PUBLIC_BEEF || process.env.REACT_APP_BEEF || `https://api.benefitsapi.com/beef`,
  },
  cms: {
    origin: process.env.NEXT_PUBLIC_CMS_API || process.env.REACT_APP_CMS_API || 'https://cms.benefitsapi.com/',
  },
  plaid: {
    origin: 'https://link.getbenepass.com',
  },
  street: {
    autocomplete: 'https://us-autocomplete-pro.api.smartystreets.com',
  },
  sentry: {
    dsn: 'https://f467ef38040a4a778c84a5375550e44b@o463927.ingest.sentry.io/5656326',
    environment: 'production',
  },
  gtag: {
    id: 'G-0M24ZSMB0N',
  },
};

const environment = {
  test,
  staging,
  production,
};

type ENV = keyof typeof environment;

type ProductionEnvironment = (typeof environment)['production'];

type Environment = ProductionEnvironment & {
  sentry?: ProductionEnvironment['sentry'];
};

const currentConfig =
  environment[(process.env.NEXT_PUBLIC_ENVIRONMENT as ENV) || (process.env.REACT_APP_ENVIRONMENT as ENV)] ?? production;

export default currentConfig as Environment;
