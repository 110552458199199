import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const StyledContent = styled.div`
  ${tw`w-full`}
`;

type Props = React.PropsWithChildren<{
  className?: string;
}>;

export const Header = ({ children, className }: Props): JSX.Element => {
  return <StyledContent className={className}>{children}</StyledContent>;
};
