import React from 'react';

const FullscreenLoader = () => (
  <div className="flex flex-col gap-6">
    <div className="px-2 md:px-6 lg:px-10 xl:px-16 h-14 md:h-20 py-2 shadow-1dp grid grid-cols-4 md:grid-cols-3 items-center">
      <div className="animate animate-pulse bg-grayscale-8 w-1/4 h-6 rounded col-span-1 self-center" />
      <div className="animate animate-pulse bg-grayscale-8 w-1/2 h-6 rounded col-span-2 md:col-span-1 justify-self-center" />
    </div>

    <div className="flex">
      <div className="max-w-screen-lg bg-grayscale-2 mx-auto w-full min-h-80 rounded-lg" />
    </div>
  </div>
);

export default FullscreenLoader;
