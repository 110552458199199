import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import routes from '.';

const TransactionsPage = lazy(() => import('./transactions-page'));
const TransactionDetailsPage = lazy(() => import('./details'));

const Home = () => (
  <Switch>
    <Route exact path={[routes.index]}>
      <Helmet>
        <title>Transactions</title>
      </Helmet>

      <TransactionsPage />
    </Route>

    <Route exact path={[routes.details]}>
      <Helmet>
        <title>Transaction details</title>
      </Helmet>

      <TransactionDetailsPage />
    </Route>
  </Switch>
);

export default Home;
