import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import routes from '.';

const Page = lazy(() => import('./magic-expense.page'));

const Home = () => (
  <Switch>
    <Route exact path={[routes.index]}>
      <Helmet>
        <title>Magic expense</title>
      </Helmet>

      <Page />
    </Route>
  </Switch>
);

export default Home;
