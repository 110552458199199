/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosInstance as Axios, AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import React from 'react';
import axios from 'axios';
import FetchContext from '../context/FetchContext';

const useFetchClient = (useAuth: () => { getAccessToken: () => Promise<string> }, api = ''): Axios => {
  const { getAccessToken } = useAuth();
  const client: Axios = axios.create({
    baseURL: api,
  });

  const paramsSerializer = (params: any) => {
    const serializeParams = (localParams: Record<string, any>): string => {
      const str: string[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key in localParams) {
        if (Object.prototype.hasOwnProperty.call(localParams, key)) {
          const value = localParams[key];
          if (Array.isArray(value)) {
            // eslint-disable-next-line no-restricted-syntax
            for (const val of value) {
              str.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
            }
          } else {
            str.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
          }
        }
      }
      return str.join('&');
    };

    return serializeParams(params);
  };

  client.interceptors.request.use((config: AxiosRequestConfig) => {
    if (config.url?.[config.url.length - 1] !== '/') {
      config.url = `${config.url}/`; // eslint-disable-line no-param-reassign
    }
    return config;
  });

  client.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      return getAccessToken().then((token: string) => {
        return {
          ...config,
          paramsSerializer,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`,
            'cache-control': 'no-cache',
          },
        };
      });
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return client;
};

const Actions = {
  RESPONSE: 'RESPONSE',
  ERROR: 'ERROR',
  LOADING: 'LOADING',
} as const;

export type RefetchFunc<T> = (limit?: number, offset?: number, params?: any, config?: any) => Promise<T | null>;

export type State<T> = {
  loading: boolean;
  data: T | null;
  error: any | null;
  status: number | null;
  doRequest: (options: any, source: any) => Promise<any>;
  refetch: RefetchFunc<T>;
};

type Action<T> =
  | {
      type: 'LOADING';
    }
  | {
      type: 'RESPONSE';
      payload: AxiosResponse<T>;
      parser: (() => any) | null | undefined;
    }
  | { type: 'ERROR'; payload: AxiosError<any> };

const reducerFunction = <T>(state: State<T>, action: Action<T>): State<T> => {
  switch (action.type) {
    case Actions.RESPONSE: {
      const response = action.payload;
      return {
        ...state,
        error: null,
        data: response.data,
        loading: false,
        status: response.status,
      };
    }
    case Actions.ERROR: {
      const error = action.payload;
      return {
        ...state,
        data: null,
        error: error.response?.data ?? null,
        status: error.response?.status ?? null,
        loading: false,
      };
    }
    case Actions.LOADING: {
      return { ...state, loading: true, data: null, error: null, status: null };
    }
    default:
      throw new Error();
  }
};

export type FetchOptions = {
  autofetch?: boolean;
};

const fetchOptionsDefault = {
  autofetch: true,
};
const useFetch = <T>(
  api: string,
  params?: AxiosRequestConfig | null,
  parser?: () => any,
  fetchOptions: FetchOptions = fetchOptionsDefault
): State<T> => {
  const client = React.useContext(FetchContext);
  const parserFunc = React.useMemo(() => parser || ((data: any) => data), [parser]);

  const doRequest = React.useCallback(
    async (options: any, source: any) => {
      try {
        const response = await client.get<T>(api, {
          cancelToken: source ? source.token : undefined,
          ...options,
        });
        response.data = parserFunc(response.data);
        return response;
      } catch (e) {
        if (axios.isCancel(e)) {
          return null;
        }
        throw e;
      }
    },
    [api, client, parserFunc]
  );

  const [state, dispatch] = React.useReducer(reducerFunction, {
    refetch: async () => null,
    doRequest,
    loading: fetchOptions.autofetch ?? true,
    data: null,
    error: null,
    status: null,
  });
  const parserRef = React.useRef(parser);

  const requestAndUpdateState = React.useCallback(
    async (source: any, options: any) => {
      try {
        const response = await doRequest(options, source);
        if (response) {
          dispatch({
            type: Actions.RESPONSE,
            payload: response,
            parser: parserRef.current,
          });
          return response.data;
        }
      } catch (e) {
        dispatch({ type: Actions.ERROR, payload: e as AxiosError });
        throw e;
      }
      return null;
    },
    [doRequest]
  );

  const refetch = React.useCallback(
    async (limit?: number, offset?: number, refetchParams?: any, config?: any) => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      dispatch({ type: Actions.LOADING });
      return requestAndUpdateState(source, {
        ...config,
        params: { ...refetchParams, limit, offset },
      });
    },
    [requestAndUpdateState]
  );

  React.useEffect(() => {
    if (!fetchOptions.autofetch) {
      return () => {
        // do nothing
      };
    }
    const { CancelToken } = axios;
    const source = CancelToken.source();
    requestAndUpdateState(source, params);

    return () => {
      source.cancel('unmount component');
    };
  }, [requestAndUpdateState, fetchOptions.autofetch, params]);

  return { ...(state as State<T>), refetch };
};

export { useFetchClient, useFetch, Actions };
