import React from 'react';

import { Route as PrimitiveRoute } from 'react-router-dom';
import { Helmet } from 'react-helmet';

type Props = React.PropsWithChildren<
  React.ComponentProps<typeof PrimitiveRoute> & {
    title?: string;
  }
>;

const Route = ({ children, ...props }: Props) => (
  <PrimitiveRoute {...props} exact>
    {props.title ? (
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
    ) : null}
    {children}
  </PrimitiveRoute>
);

export default Route;
