/**
 * @generated SignedSource<<bcc280900c3519cf401baba2dd2cb2b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type checkOnboardingQuery$variables = {};
export type checkOnboardingQuery$data = {
  readonly onboardings: ReadonlyArray<{
    readonly apiID: string;
    readonly isComplete: boolean;
  }>;
};
export type checkOnboardingQuery = {
  response: checkOnboardingQuery$data;
  variables: checkOnboardingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "apiID",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isComplete",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "checkOnboardingQuery",
    "selections": [
      {
        "alias": "onboardings",
        "args": null,
        "concreteType": "OnboardingBuilder",
        "kind": "LinkedField",
        "name": "user__Onboarding_GetOnboardings",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "checkOnboardingQuery",
    "selections": [
      {
        "alias": "onboardings",
        "args": null,
        "concreteType": "OnboardingBuilder",
        "kind": "LinkedField",
        "name": "user__Onboarding_GetOnboardings",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "39d80ca7315a02a12e3e14f67b1b98af",
    "id": null,
    "metadata": {},
    "name": "checkOnboardingQuery",
    "operationKind": "query",
    "text": "query checkOnboardingQuery {\n  onboardings: user__Onboarding_GetOnboardings {\n    apiID\n    isComplete\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0644c5d0b17bba0e9d410c84833e4e00";

export default node;
