import React from 'react';
import { down } from 'styled-breakpoints';
import useBreakpoint from './useBreakpoint';

type ExtendedWindow = Window & {
  MSStream?: unknown;
  opera?: string;
};

/**
 * Determine the mobile operating system.
 * https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
 */
const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as ExtendedWindow).opera || '';

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows-phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as ExtendedWindow).MSStream) {
    return 'ios';
  }

  return 'unknown';
};

const useMobileAgent = (): {
  isMobile: boolean;
  platform: 'windows-phone' | 'android' | 'ios' | 'unknown';
} => {
  const isMobileScreen = useBreakpoint(down('sm'));
  const agent = React.useMemo(() => getMobileOperatingSystem(), []);
  const isMobileAgent = React.useMemo(() => agent === 'ios' || agent === 'android', [agent]);
  const isMobile = React.useMemo(() => isMobileScreen && isMobileAgent, [isMobileScreen, isMobileAgent]);

  return { isMobile, platform: agent };
};

export default useMobileAgent;
