import { lazy } from 'react';
import Route from '@app/components/route';

import { Switch } from 'react-router-dom';

import routes from '.';

const CardsPage = lazy(() => import('./card-page'));
const CardDetailsPage = lazy(() => import('./card-details-page'));
const CreateCardMocal = lazy(() => import('./create-card'));
const UpdateBillingAddress = lazy(() => import('./update-billing-address'));

const Cards = () => (
  <>
    <Switch>
      <Route exact path={[routes.index, routes.create.pathname]} title="Cards">
        <CardsPage />
      </Route>

      <Route path={[routes.details]} title="Card details">
        <CardDetailsPage />
      </Route>
    </Switch>

    <Switch>
      <Route path={[routes.updateBillingAddress]} title="Update billing address" exact>
        <UpdateBillingAddress />
      </Route>

      <Route path={[routes.create.route]} title="Create card" exact>
        <CreateCardMocal />
      </Route>
    </Switch>
  </>
);

export default Cards;
