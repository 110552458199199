import React from 'react';
import customTwMerge from '../../../utils/twMerge';

type Props = React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;

const FullscreenBody = ({ ...props }: Props) => (
  <div {...props} className={customTwMerge('max-w-screen-xl mx-auto', props.className)} />
);

export default FullscreenBody;
