import React from 'react';
import SentryCategories from 'utils/src/enums/SentryCategories';
import currentConfig from '@config';

import { ApolloClient, HttpLink, InMemoryCache, ApolloLink } from '@apollo/client/core';
import { ApolloProvider as ApolloProviderPrimitive, useApolloClient } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { logCrumb, logError } from 'utils/src/Logs';
import { useUserWorkspace } from '@hooks/use-user-workspace';

import projectPackage from '../../../package.json';

import { getAccessToken } from '../auth/transition';

import type { RefetchQueriesInclude } from '@apollo/client/core';

export const graphQLBenepassURL = `${currentConfig.api.graphql}`;
const { version } = projectPackage;

const logResourceCrumb = (message: string, resource: Record<string, unknown> = {}) => {
  return logCrumb(SentryCategories.GRAPHQL_APOLLO, message, resource);
};

const logResourceError = (error: Error) => {
  return logError(SentryCategories.GRAPHQL_APOLLO, error);
};

const fetchApollo = async (uri: URL | RequestInfo, options: RequestInit) => {
  const { variables, query } = JSON.parse(options.body?.toString() || '{}');
  logResourceCrumb(`[apollo] - posting ${query} with ${JSON.stringify(variables)}`);

  const authorization = await getAccessToken();

  const headers = {
    Authorization: `Bearer ${authorization}`,
    'x-benepass-platform': 'web',
    'x-benepass-client': 'employee-web',
    'x-benepass-version': process.env.npm_package_version || version,
  };

  return fetch(uri, {
    ...options,
    headers: {
      ...options.headers,
      ...headers,
    },
  });
};

const generateApolloClient = ({ headers }: { headers?: Record<string, string> } = {}): ApolloClient<unknown> => {
  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors }) => {
        // We shouldn't have unauthorized errors
        const hasUnauthorizedError = graphQLErrors?.some((error) => error.extensions?.status === 401);

        if (hasUnauthorizedError) {
          getAccessToken().then(() => logResourceCrumb('[apollo] - Requested new token', {}));
        }
      }),
      new RetryLink({
        delay: {
          initial: 300,
          max: Infinity,
          jitter: true,
        },
        attempts: {
          max: 10,
          retryIf: async (error) => {
            if (error) await getAccessToken();
            return !!error;
          },
        },
      }),
      new HttpLink({
        uri: graphQLBenepassURL,
        // @ts-expect-error Apollo fetch error
        fetch: fetchApollo,
        headers,
      }),
    ]),
    cache: new InMemoryCache(),
  });
};

export const refetchApolloQueries = async (client: ApolloClient<unknown>, queries: RefetchQueriesInclude) => {
  try {
    await client.refetchQueries({ include: queries });
  } catch (error) {
    logResourceError(Error(`Invalidation error: ${error}`));
  }
};

export const useApolloQueriesInvalidation = () => {
  const client = useApolloClient();
  return { refetch: (queries: RefetchQueriesInclude) => refetchApolloQueries(client, queries) };
};

export const ApolloProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [userWorkspace] = useUserWorkspace();

  const client = generateApolloClient({
    headers: {
      ...(userWorkspace ? { 'x-benepass-workspace-id': userWorkspace } : {}),
    },
  });

  return <ApolloProviderPrimitive client={client}>{children}</ApolloProviderPrimitive>;
};
