type Props = {
  hideTitle?: boolean;
};

const GenericPageLoader = ({ hideTitle }: Props) => (
  <div className="flex flex-col gap-6 pb-12">
    {hideTitle ? null : (
      <div className="flex flex-col gap-2 w-full pt-1">
        <div className="h-10 w-52 rounded-md bg-grayscale-8" />
        <div className="h-6 w-96 rounded-md bg-grayscale-8" />
      </div>
    )}
    <div className="flex flex-col">
      <div className="w-full h-96 bg-grayscale-8 animate-pulse rounded-t-md" />
      <div className="w-full h-96 bg-grayscale-8 animate-pulse" />
      <div className="w-full h-96 bg-grayscale-8 animate-pulse rounded-b-md" />
    </div>
  </div>
);

export default GenericPageLoader;
