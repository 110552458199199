/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore -- vanilla js
import * as cognitoAuth from './Auth';

export const {
  getAccessTokenSync,
  getAccessToken,
  logout,
  useAuthentication,
  AuthenticationProvider,
  AuthenticationContext,
}: { [key: string]: any } = cognitoAuth;
