import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import routes from '.';

const HomePage = lazy(() => import('./home-page'));

const Home = () => (
  <Route exact path={[routes.index, routes.home]}>
    <Helmet>
      <title>Home</title>
    </Helmet>

    <HomePage />
  </Route>
);

export default Home;
