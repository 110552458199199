import React from 'react';
import { useTheme } from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const useBreakpoint = (breakpoint: any): boolean => {
  // Get the media query to match
  const query = breakpoint({
    theme: useTheme(),
  }).replace(/^@media\s*/, '');
  const [isBreak, setIsBreak] = React.useState(false);

  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    const mq = window.matchMedia(query);
    const handleChange = (event: MediaQueryListEvent) => {
      setIsBreak(event.matches);
    };

    setIsBreak(mq.matches);

    // Safari < 14 can't use addEventListener on a MediaQueryList
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#Browser_compatibility
    if (!mq.addEventListener) {
      // Update the state whenever the media query match state changes
      mq.addListener(handleChange);

      // Clean up on unmount and if the query changes
      return () => {
        mq.removeListener(handleChange);
      };
    }
    mq.addEventListener('change', handleChange);

    return () => {
      mq.removeEventListener('change', handleChange);
    };
  }, [query]);

  return isBreak;
};

export default useBreakpoint;
