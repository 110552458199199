import React, { useEffect } from 'react';
import { NetworkError } from 'rest-hooks';

import { red } from '@benepass/colors';
import Text from 'react-components/src/components/text';

import { useAuthentication } from '@data/auth/transition';

type Props = {
  error: NetworkError;
};

const NetworkFailureDash = ({ error }: Props) => {
  const { status, response } = error;
  const statusText = response?.statusText;
  const statusMessage = statusText || 'No status text available';
  const { isAuthenticated, loginWithRedirect } = useAuthentication();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect]);

  return (
    <div style={styles.container} data-testid="network-failure-dash">
      <div>
        <div style={styles.content}>
          <div style={styles.header}>
            <Text type="header-3">Oops!</Text>
          </div>
          <div style={styles.section}>
            <Text type="body">
              Something has gone wrong while trying to load your data. Please try again in a minute. If that does not
              work, please contact us for help! Thank you and sorry for the trouble.
            </Text>
          </div>
          <div style={styles.section}>
            <Text color={red[100]}>{`Error status: ${status}, Status text: ${statusMessage}`}</Text>
          </div>
          {/* TODO: add sad but cute illustration */}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flex: 1,
  },
  content: {
    padding: '24px',
  },
  header: {
    alignItems: 'center',
  },
  section: {
    marginTop: '20px',
    flex: 1,
  },
};

export default NetworkFailureDash;
