import { lazy } from 'react';
import Route from '@app/components/route';
import { Switch } from 'react-router-dom';

import routes from '.';

const SettingsPage = lazy(() => import('./settings-page'));
const DocumentsPage = lazy(() => import('./documents'));

const UpdatePersonalEmailModal = lazy(() => import('./update-personal-email'));
const ValidateInformationModal = lazy(() => import('./validate-information'));

const Settings = () => (
  <>
    <Switch>
      <Route path={[routes.index]} title="Settings">
        <SettingsPage />
      </Route>
      <Route path={[routes.documents, routes.documentsForms, routes.documentsHsaStatements]} title="Settings">
        <DocumentsPage />
      </Route>
    </Switch>

    <Route path={routes.editPersonalEmail}>
      <UpdatePersonalEmailModal />
    </Route>

    <Route path={routes.validateInformation}>
      <ValidateInformationModal />
    </Route>
  </>
);

export default Settings;
