/**
 * @generated SignedSource<<c0b75a694de03d4b55c8392cb4e0d613>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type workspaceRedirectionWidgetQuery$variables = {};
export type workspaceRedirectionWidgetQuery$data = {
  readonly res: {
    readonly reason: string;
    readonly title: string;
    readonly workspace: {
      readonly apiID: string;
    };
  } | null;
};
export type workspaceRedirectionWidgetQuery = {
  response: workspaceRedirectionWidgetQuery$data;
  variables: workspaceRedirectionWidgetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reason",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "apiID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "workspaceRedirectionWidgetQuery",
    "selections": [
      {
        "alias": "res",
        "args": null,
        "concreteType": "UserWorkspaceRedirection",
        "kind": "LinkedField",
        "name": "user__Identity_GetWorkspaceRedirection",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserWorkspace",
            "kind": "LinkedField",
            "name": "workspace",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "workspaceRedirectionWidgetQuery",
    "selections": [
      {
        "alias": "res",
        "args": null,
        "concreteType": "UserWorkspaceRedirection",
        "kind": "LinkedField",
        "name": "user__Identity_GetWorkspaceRedirection",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserWorkspace",
            "kind": "LinkedField",
            "name": "workspace",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8e83bc4d139f00fb705a6120437533e1",
    "id": null,
    "metadata": {},
    "name": "workspaceRedirectionWidgetQuery",
    "operationKind": "query",
    "text": "query workspaceRedirectionWidgetQuery {\n  res: user__Identity_GetWorkspaceRedirection {\n    title\n    reason\n    workspace {\n      apiID\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "735eca527b2bd892c9c8024124167cec";

export default node;
