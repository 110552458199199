import styled from 'styled-components';
import tw from 'twin.macro';
import Spin from '../../Spin';

const StyledOverlay = styled.div`
  ${tw`fixed inset-0 z-50 bg-white bg-opacity-90`}
  ${tw`flex items-center justify-center flex-col`}
  transform: translateZ(0px);
`;

type Props = {
  dataTestId?: string;
};

const LoadingOverlay = ({ dataTestId }: Props): JSX.Element => (
  <StyledOverlay data-testid={dataTestId}>
    <Spin />
  </StyledOverlay>
);

export default LoadingOverlay;
