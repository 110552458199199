import React from 'react';
import * as Dropdown from 'react-components/src/components/dropdown';

type Props = React.PropsWithChildren<{
  isDropdownOpen: boolean;
  setIsDropdownOpen: (value: boolean) => void;
}>;

const Root = ({ children, isDropdownOpen, setIsDropdownOpen }: Props) => (
  <Dropdown.Root open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
    {children}
  </Dropdown.Root>
);

export default Root;
