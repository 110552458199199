import React from 'react';
// @ts-expect-error JS Module
import * as serviceWorker from '@utils/serviceWorker';
import * as Sentry from '@sentry/react';
import current from '@config';
import App from '@app';

import { AnalyticsProvider } from 'utils/src/hooks/useAnalytics';
import { AuthenticationProvider } from '@data/auth/transition';
import { initSentry } from '@data/services/sentry';
import { createRoot } from 'react-dom/client';

import 'react-components/src/utils/styles';
import './css/App.css';

if (current.track && current.sentry) {
  initSentry();
}

const Root = () => (
  <Sentry.ErrorBoundary fallback={<>An error has occured</>}>
    <AnalyticsProvider gtagId={((current as Record<string, unknown>)?.gtag as Record<string, string>)?.id}>
      <AuthenticationProvider
        redirectUri={window.location.origin}
        scope="openid profile email"
        cacheLocation="localstorage"
        {...current.auth}
      >
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </AuthenticationProvider>
    </AnalyticsProvider>
  </Sentry.ErrorBoundary>
);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<Root />);

serviceWorker.unregister();
