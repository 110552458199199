import React from 'react';
import Route from '@app/components/route';

import routes from '.';

const FileUploadRedirectPage = React.lazy(() => import('./file-upload-redirect-page'));

const FileUploadsRoutes = () => (
  <Route path={routes.fileUpload} title="File Upload">
    <FileUploadRedirectPage />
  </Route>
);

export default FileUploadsRoutes;
