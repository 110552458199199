import React from 'react';
import Loader from 'react-components/src/ui/layout/fullscreen-container/fullscreen-loader';

import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { SuspenseWrapper } from 'react-components';
import FullscreenContainer from 'react-components/src/ui/layout/fullscreen-container';
import routes from '.';

const NewExpensePage = React.lazy(() => import('./new-expense.page'));

const Home = () => (
  <Route exact path={[routes.index, routes.linkBankAccount]}>
    <FullscreenContainer className="bg-grayscale-2 min-h-screen">
      <Helmet>
        <title>Get reimbursed</title>
      </Helmet>

      <SuspenseWrapper loader={<Loader />}>
        <NewExpensePage />
      </SuspenseWrapper>
    </FullscreenContainer>
  </Route>
);

export default Home;
