import type { UserWorkspace } from '@app/data/user-workspaces.query';

export const shouldForceWorkspaceSelection = (workspaces: UserWorkspace[], userWorkspace: string | undefined) => {
  if (!userWorkspace && workspaces.length === 1 && workspaces?.[0]?.loginRequired === true) {
    return true;
  }

  if (!userWorkspace && (workspaces.length > 1 || workspaces.every((workspace) => workspace.loginRequired))) {
    return true;
  }

  const foundWorkspace = workspaces.find((workspace) => workspace.apiID === userWorkspace);
  if (!foundWorkspace) {
    return true;
  }

  return false;
};
